import Image from 'next/legacy/image'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { breakpoints } from '@/styles'
import useMediaQuery from '@/hooks/use-media-query'
import { Card, Description, Text } from '@/features/Renters/elements'

import { ImageWrapper } from './elements'
import tenantImage from './images/tenant.png'

const ImageCard = styled(Card)`
  height: 820px;
  width: 560px;
  margin-right: 30px;

  @media screen and (max-width: ${breakpoints.card}) {
    margin-right: 0;
    margin-bottom: 15px;
    width: 90%;
  }
`

const TenantScreening = () => {
  const { t } = useTranslation('home')
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  return (
    <ImageCard>
      <Text>{t('home:Effortless tenant screening report')}</Text>
      <Description>
        {t(
          'home:See credit checks, unemployment verifications, and more, all in one place.'
        )}
      </Description>
      <ImageWrapper>
        <Image
          src={tenantImage}
          alt="Screening"
          sizes={!displayMobile ? '640px' : '384px'}
          layout="fill"
          objectFit="contain"
          placeholder="blur"
        />
      </ImageWrapper>
    </ImageCard>
  )
}

export default TenantScreening
