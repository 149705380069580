import { useMemo } from 'react'
import Image from 'next/legacy/image'
import styled from 'styled-components'
import range from 'lodash/range'

const makeHide = (index, length, breakpoint) => {
  const wrapBreakpoint = value => {
    if (!breakpoint) return value
    return `
      @media (max-width: ${breakpoint}) {
        ${value}
      }
    `
  }
  const select = range(1, length + 1)
    .filter(item => item !== index)
    .map(item => `> span:nth-of-type(${item})`)
    .join(', \n')
  return wrapBreakpoint(`
    ${select} {
      display: none !important;
    }
    > span:nth-of-type(${index}) {
      display: block !important;
    }
  `)
}

const makeImageSwitch = breakpoints => {
  const images = breakpoints.length + 1
  const parts = range(1, images + 1).map(image =>
    makeHide(image, images, breakpoints?.[image - 2])
  )
  return parts.join('\n')
}

const BackgroundWrapper = styled.div`
  overflow: hidden;
  position: ${props => props.position};
  width: 100%;
  max-width: ${props =>
    props.maxWidth !== 'unset' ? `${props.maxWidth}px` : 'unset'};
  bottom: 0;
  user-select: none;
  ${({ customStyle }) => customStyle};
`

// While the Next image component will automatically download
// and use an appropriately sized image, in-some cases
// we want to use different images depending on the breakpoint.
// This can be needed for background images where different
// screen sizes require a different zoom level.
const BackgroundImage = ({
  images,
  breakpoints,
  name,
  position = 'absolute',
  maxWidth = 'unset',
  sizes = [],
  placeholder = 'blur',
  ...rest
}) => {
  const style = useMemo(() => makeImageSwitch(breakpoints), [breakpoints])
  const actualSizes = sizes && images.length == sizes?.length ? sizes : []
  return (
    <BackgroundWrapper
      customStyle={style}
      position={position}
      maxWidth={maxWidth}
    >
      {images.map((image, i) => (
        <Image
          key={`${name}-${i + 1}`}
          layout="responsive"
          placeholder={placeholder}
          objectFit="cover"
          objectPosition="center bottom"
          alt=""
          sizes={actualSizes[i]}
          {...rest}
          src={image}
        />
      ))}
    </BackgroundWrapper>
  )
}

export default BackgroundImage
