import styled from 'styled-components'

export const ImageWrapper = styled.div`
  position: relative;
  height: 508px;
  padding-bottom: 30px;
  width: 254px;
  margin: 70px auto 0;

  > span {
    overflow: visible !important;

    img {
      filter: drop-shadow(20px 10px 10px #bfbfbf) !important;
      background-repeat: no-repeat;
    }
  }
`
