import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { breakpoints, fontWeights } from '@/styles'
import {
  SubHeader,
  SubHeaderTitle,
  TextWrapper
} from '@/features/Home/elements'
import BackgroundImage from '@/components/background-image'
import { Button } from '@/components/button'

import CreateContract from './contracts-card'
import heroImageDesktop from './images/landlord-hero-desktop.webp'
import heroImageMobile from './images/landlord-hero-mobile.webp'
import TenantScreening from './tenant-screening-card'

const Wrapper = styled.div`
  padding-bottom: 170px;

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    padding-bottom: 100px;
  }
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`

const ListWrapper = styled.div`
  width: 1020px;
  margin-top: 30px;

  @media screen and (max-width: ${breakpoints.desktop}) {
    width: auto;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    width: 292px;
    margin-top: 40px;
  }
`

const List = styled.ul`
  column-count: 3;

  @media screen and (max-width: ${breakpoints.desktop}) {
    column-count: 1;
    padding-right: 30px;
  }
`

const ListItem = styled.li`
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.2px;
  line-height: 24px;
  margin: 0 30px 49px 0;

  @media screen and (max-width: ${breakpoints.desktop}) {
    columns: 1;
    margin: 0 0 38px 0;
  }

  @media screen and (max-width: ${breakpoints.phoneMax}) {
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 30px 0;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: ${breakpoints.card}) {
    flex-direction: column;
    align-items: center;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  @media (max-width: ${breakpoints.phoneMax}) {
    margin-top: 50px;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const ButtonText = styled.span`
  text-align: center;
  min-width: 160px;
`

const ForLandlords = () => {
  const { t } = useTranslation('home')
  const title = t('home:For Landlords')
  const subTitle = t('home:Rent out your listing as fast as 7 days.')
  return (
    <Wrapper>
      <TextWrapper>
        <SubHeaderTitle
          textAlign={['left']}
          padding={['170px 0 15px', '100px 0 15px']}
          fontSize={['24px', '18px']}
        >
          {title}
        </SubHeaderTitle>
        <SubHeader
          textAlign={['left']}
          padding={['0 0 20px', '0 0 50px']}
          maxWidth={640}
        >
          {subTitle}
        </SubHeader>
      </TextWrapper>
      <TopContainer>
        <BackgroundImage
          images={[heroImageDesktop, heroImageMobile]}
          breakpoints={[breakpoints.phoneMax]}
          position="static"
          maxWidth={905}
          quality={100}
        />
        <ListWrapper>
          <List>
            <ListItem>
              {t('home:The only rental communication tool you need.')}
            </ListItem>
            <ListItem>
              {t('home:Instant access to all rental documents on any device.')}
            </ListItem>
            <ListItem>
              {t(
                'home:Create, send, and sign auto-filled digital contracts to tenants.'
              )}
            </ListItem>
            <ListItem>
              {t(
                'home:Easily organize inspection reports, applications, contracts, and more.'
              )}
            </ListItem>
            <ListItem>
              {t('home:Layered security to protect and encrypt your data.', {
                ns: 'home'
              })}
            </ListItem>
            <ListItem>{t('home:Never lose a rental document again.')}</ListItem>
          </List>
        </ListWrapper>
      </TopContainer>

      <CardContainer>
        <TenantScreening />
        <CreateContract />
      </CardContainer>

      <ButtonWrapper>
        <StyledLink href="/landlords" passHref>
          <Button
            id="home-landlords-letsgetstarted-click"
            theme="secondary"
            radius={50}
            shadow
          >
            <ButtonText>{t(`home:Let's get started`)}</ButtonText>
          </Button>
        </StyledLink>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default ForLandlords
