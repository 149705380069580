import Image from 'next/legacy/image'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { breakpoints } from '@/styles'
import useMediaQuery from '@/hooks/use-media-query'
import { Card, Description, Text } from '@/features/Renters/elements'

import { ImageWrapper } from './elements'
import contractImage from './images/contract@2x.png'

const ImageCard = styled(Card)`
  height: 820px;
  width: 560px;

  @media screen and (max-width: ${breakpoints.card}) {
    width: 90%;
  }
`

const CreateContract = () => {
  const { t } = useTranslation('home')
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)
  return (
    <ImageCard>
      <Text>{t('home:Create contracts in minutes')}</Text>
      <Description>
        {t(
          'home:Create error-free contracts in minutes from received applications.'
        )}
      </Description>
      <ImageWrapper>
        <Image
          src={contractImage}
          alt="Contract"
          sizes={!displayMobile ? '640px' : '384px'}
          layout="fill"
          objectFit="contain"
          placeholder="blur"
        />
      </ImageWrapper>
    </ImageCard>
  )
}

export default CreateContract
